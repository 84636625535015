import { Card, Grid, Paper } from '@mui/material';
import { Container } from '@mui/system';
import PrivateLayout from 'layouts/private';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { createWorker } from "tesseract.js";


export default function OCR() {
  const [preview, setPreview] = useState();
  const [ocr, setOcr] = useState("");

  const onDrop = (acceptedFiles, fileRejections) => {
    if (fileRejections.length || !acceptedFiles.length) return;
    const file = acceptedFiles[0];
    setOcr("");

    const reader = new FileReader();

    reader.onloadend = () => {
      const imageDataUri = reader.result;
      setPreview(imageDataUri);
      convertImageToText(imageDataUri);
    };
    reader.readAsDataURL(file);

  };


  const { getRootProps, getInputProps, isDragActive, acceptedFiles, fileRejections } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    multiple: false,
  });

  // 
  const worker = createWorker({
    logger: (m) => {
      // console.log(m);
    }
  });

  const convertImageToText = async (imageData) => {
    if (!imageData) return;
    setOcr("loading....");
    await worker.then(async (w) => {
      console.log(w);
      await w.loadLanguage("eng+mya");
      await w.initialize("eng+mya");
      const { data: { text } } = await w.recognize(imageData);
      setOcr(text);
      await w.terminate();
    });
  };

  return (
    <PrivateLayout>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {preview ? <img src={preview} /> :
                  <p>Drag 'n' drop some files here, or click to select files</p>
                }
              </div>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              {ocr}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </PrivateLayout>
  );
}
