import React from 'react';

import PrivateLayout from "layouts/private";
import { fetchDealerPayment, updateDealerPayment } from "apiRequest/dealer-payment";
import { fetchPaymentProviders } from "apiRequest/payment-provider";
import { useNavigate, useParams } from 'react-router-dom';
import DealerPaymentForm from 'components/forms/DealerPaymentForm';
import isEmpty from 'lodash.isempty';
import updateDealerPaymentValidation from 'formValidations/updateDealerPayment';
import FormContainer from 'components/FormContainer';
import useToken from 'hooks/useToken';
import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { defaultError } from 'utils/message';

export default function DealerPaymentUpdate() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { token } = useToken();

  const { data: paymentProviders } = useQuery("payment-fetchPaymentProviders", () => {
    return fetchPaymentProviders({ token });
  });

  const paymentProviderOptions = paymentProviders?.data.data;

  const { data: dealerPayment, isLoading, isError, error } = useQuery("dealerPayment-fetchDealerPayment", () => {
    return fetchDealerPayment({ token, id });
  });

  const mutation = useMutation(data => {
    return updateDealerPayment({ token, data, id });
  });


  const handleOnSubmit = async ({ values: data }) => {
    const submitData = {
      ...data
    };

    mutation.mutate(submitData, {
      onSuccess: () => {
        navigate('/dealer-payments', { replace: true });
      },
      onError: (error) => {
        if (error.response?.status !== 422) {
          toast.error(error.message || defaultError);
          console.error(error);
        }
      }
    });
  };

  return (
    <PrivateLayout loading={isLoading} error={error} isError={isError}>
      {!isEmpty(dealerPayment?.data.data) &&
        <FormContainer
          initialValues={dealerPayment?.data.data}
          title="Edit Dealer Payment"
          subheader={dealerPayment?.data.data.name}
          onSubmit={handleOnSubmit}
          validationSchema={updateDealerPaymentValidation}
          serverError={mutation.error}
        >
          <DealerPaymentForm loading={mutation.isLoading} paymentProviderOptions={paymentProviderOptions} />
        </FormContainer>
      }
    </PrivateLayout >
  );
}
