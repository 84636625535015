import { FormControl, FormControlLabel, FormHelperText,  Switch } from '@mui/material';
import { useField } from 'informed';
import React from 'react';

export default function SwitchInput(props) {
  const { fieldState, fieldApi, render, ref, userProps } = useField(props);

  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { field, onChange, onBlur, label, size, helperText, margin = "dense" } = userProps;

  const checked = typeof value === "boolean" ? value : value === 1;

  return render(
    <React.Fragment>
      <FormControl fullWidth margin={margin} error={Boolean(fieldState.error)} size={size}>
        <FormControlLabel
          control={
            <Switch
              color="success"
              checked={checked}
              field={field}
              ref={ref}
              onChange={e => {
                setValue(e.target.checked);
                if (onChange) {
                  onChange(e);
                }
              }}
              onBlur={e => {
                setTouched(true);
                if (onBlur) {
                  onBlur(e);
                }
              }}
            />
          }
          label={checked ? label : `Not ${label}`}
        />
        <FormHelperText>{helperText === false ? "" : fieldState.error || " "}</FormHelperText>
      </FormControl>
    </React.Fragment >
  );
}
