import { Button, Grid } from '@mui/material';
import { fetchDealerPayments, updateDealerPaymentActivation } from 'apiRequest/dealer-payment';
import { fetchPaymentProviders } from "apiRequest/payment-provider";
import SelectInput from 'components/forms/fields/SelectInput';
import ISTable from 'components/lSTable';
import useToken from 'hooks/useToken';
import PrivateLayout from 'layouts/private';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import dateTime from 'utils/datetime';
import { refetchIntervalTime } from 'config';
import BooleanHighight from 'components/BooleanHighight';
import toast from 'react-hot-toast';
import { defaultError } from 'utils/message';
import TableButtonsContainer from 'components/TableButtonsContainer';


export default function DealerPaymentList() {
  const { token } = useToken();
  const [params, setParams] = useState({
    sort_by: {
      "key": "id",
      "order": "desc"
    }
  });

  const { isLoading, isError, data: dealerPayments, error, refetch } = useQuery(
    ["dealerPayment-fetchDealerPayments", params],
    () => {
      return fetchDealerPayments({ token, params });
    }, {
    refetchInterval: refetchIntervalTime
  });

  const { data: paymentProviders } = useQuery("payment-fetchPaymentProviders", () => {
    return fetchPaymentProviders({ token });
  });

  const paymentProviderOptions = paymentProviders?.data.data;

  const mutation = useMutation(({ id, data }) => {
    return updateDealerPaymentActivation({ token, id, data });
  });

  const handleActivationUpdateButton = ({ id, checked }) => {
    const data = {
      id,
      data: {
        is_active: checked
      }
    };

    mutation.mutate(data, {
      onSuccess: () => {
        toast.success("Update Activation Success!");
        refetch();
      },
      onError: (error) => {
        toast.error(error.message || defaultError);
        console.error(error);
      }
    });
  };

  const handlePageChange = (page) => {
    setParams({ ...params, page });
  };

  const handleSearchSubmit = (search) => {
    setParams({
      ...params,
      filters: { ...params.filters, search }
    });
  };

  const hanldeFilterSubmit = (data) => {
    setParams({
      ...params,
      filters: { ...params.filters, ...data }
    });
  };

  const hanldeSortClick = (data) => {
    setParams({ ...params, sort_by: data });
  };

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => row.id,
      sort: true,
    },
    {
      key: 'provider',
      label: 'Provider',
      selector: row => row.provider,
    },
    {
      key: 'account_name',
      label: 'Account Name',
      selector: row => row.account_name,
    },
    {
      key: 'account_id',
      label: 'Account ID',
      selector: row => row.account_id,
    },
    {
      key: 'is_active',
      label: 'Active',
      selector: row => <BooleanHighight bool={row.is_active} id={row.id} onChange={handleActivationUpdateButton} />,
    },
    {
      key: 'created_at',
      label: 'Date',
      selector: row => dateTime.format(row.created_at),
      sort: true
    },
    {
      key: 'actions',
      label: 'Actions',
      selector: row => <Button component={RouterLink} to={`/dealer-payments/${row.id}/update`}>Edit</Button>
    },
    // eslint-disable-next-line
  ], []);

  return (
    <PrivateLayout loading={isLoading} isError={isError} error={error}>
      <TableButtonsContainer>
        <Button
          variant="contained"
          component={RouterLink}
          to={`/dealer-payments/create`}
        >
          Add New Dealer Payment
        </Button>
      </TableButtonsContainer>

      <ISTable
        title="Dealer Payments"
        loading={isLoading}
        columns={columns}
        data={dealerPayments?.data.data}
        pagination
        count={dealerPayments?.data.total}
        page={dealerPayments?.data.current_page}
        rowsPerPage={dealerPayments?.data.per_page}
        onPageChange={handlePageChange}
        search
        searchFields={['account_name', 'account_id']}
        searchSubmit={handleSearchSubmit}
        filter
        filterSubmit={hanldeFilterSubmit}
        FilterFields={FilterFields}
        filterFields={['provider']}
        filterFieldsProps={{ paymentProviderOptions }}
        sortClick={hanldeSortClick}
      />
    </PrivateLayout>
  );
}

const FilterFields = ({ paymentProviderOptions }) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} width="250px" >
      <Grid item xs={12}>
        <SelectInput field="provider" label="Provider" size="small" margin="none" helperText={false}
          options={paymentProviderOptions}
        />
      </Grid>
    </Grid>
  );
};
