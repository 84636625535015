import { Box, Collapse, Divider, Drawer, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import MUINavLink from 'components/MUINavLink';

const menus = [
  {
    'text': 'Transactions',
    'to': '/transactions',
  },
  {
    'text': 'Dealer Payments',
    'to': '/dealer-payments',
  },
  {
    'type': 'parent',
    'text': 'Reports',
  },
  {
    'type': 'children',
    'menus': [
      {
        'text': 'Transactions',
        'to': '/reports/transactions',
      },
      {
        'text': 'Dealer Payments',
        'to': '/reports/dealer-payments',
      },
    ]
  },
  {
    'text': 'OCR (not ready)',
    'to': '/ocr',
  },
];

export default function Sidebar({ isLoading, me }) {
  return (
    <Drawer
      open
      variant="persistent"
      PaperProps={{
        style: {
          top: 64,
          height: 'calc(100% - 64px)',
          width: '256px'
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        component="nav"
        height="100%"
      >
        <Box display="flex" flexDirection="column" alignItems="center" p={2}>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            {!isLoading && (me?.data.data.name)}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {!isLoading && `${me?.data.data.server?.code} Staff `}
          </Typography>
        </Box>
        <Divider />

        <Box p={2}>
          <List component="nav">
            {menus.map(({ type, text, to, menus, disabled }) => {
              if (type === 'children') {
                return (
                  <Collapse key={`child_of_${text}`} in={true} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding >
                      {menus.map(({ text, to, disabled }) => {
                        return (
                          <ListItem sx={{ pl: 4 }} key={to} button component={MUINavLink} to={to} disabled={disabled}>
                            <ListItemText>{text}</ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                );
              }
              if (type === 'parent') {
                return (
                  <ListItem key={text} button disabled={disabled}>
                    <ListItemText>{text}</ListItemText>
                    <ExpandLessIcon />
                  </ListItem>
                );
              }
              return (
                <ListItem key={to} button component={MUINavLink} to={to} disabled={disabled}>
                  <ListItemText>{text}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Divider />
        <Box textAlign="center" p={2}>
          <Typography fontSize="small">
            Obet99 Staff App 0.1
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
}
