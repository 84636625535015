import React from 'react';
import TextInput from './fields/TextInput';
import SubmitButton from './fields/SubmitButton';
import { Divider } from '@mui/material';
import SelectInput from './fields/SelectInput';
import SwitchInput from './fields/SwitchInput';

export default function DealerPaymentForm({ loading, paymentProviderOptions }) {
  return (
    <>
      <SelectInput field="provider" label="Provider" options={paymentProviderOptions} />
      <TextInput field="account_id" label="Account ID" />
      <TextInput field="account_name" label="Account Name" />
      <SwitchInput field="is_active" label="Active"  />
      <Divider />
      <SubmitButton loading={loading}>Submit</SubmitButton>
    </>
  );
}
