import React from 'react';

import PrivateLayout from "layouts/private";
import { createDealerPayment } from "apiRequest/dealer-payment";
import { fetchPaymentProviders } from "apiRequest/payment-provider";
import DealerPaymentForm from 'components/forms/DealerPaymentForm';
import createDealerPaymentValidation from 'formValidations/createDealerPayment';
import FormContainer from 'components/FormContainer';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { defaultError } from 'utils/message';
import toast from 'react-hot-toast';
import useToken from 'hooks/useToken';

export default function DealerPaymentCreate() {
  const navigate = useNavigate();

  const { token } = useToken();

  const { data: paymentProviders } = useQuery("payment-fetchPaymentProviders", () => {
    return fetchPaymentProviders({ token });
  });

  const paymentProviderOptions = paymentProviders?.data.data;

  const mutation = useMutation(data => {
    return createDealerPayment({ token, data });
  });

  const handleOnSubmit = async ({ values: data }) => {
    const submitData = {
      ...data
    };

    mutation.mutate(submitData, {
      onSuccess: () => {
        navigate('/dealer-payments', { replace: true });
      },
      onError: (error) => {
        if (error.response?.status !== 422) {
          toast.error(error.message || defaultError);
          console.error(error);
        }
      }
    });
  };

  return (
    <PrivateLayout>
      <FormContainer
        title="Dealer Payment"
        subheader="Add New"
        onSubmit={handleOnSubmit}
        validationSchema={createDealerPaymentValidation}
        initialValues={{ "is_active": true }}
        serverError={mutation.error}
      >
        <DealerPaymentForm loading={mutation.isLoading} paymentProviderOptions={paymentProviderOptions} />
      </FormContainer>
    </PrivateLayout>
  );
};
