import { format, formatISO, parseISO, isValid } from 'date-fns';

const dateTime = {
  format: (isoString, options) => {
    const parsed = parseISO(isoString);
    if (!isValid(parsed)) {
      return isoString;
    }
    if (options?.type) {
      switch (options.type) {
        case 'DOMString':
          return format(parsed, "yyyy-MM-dd'T'HH:mm:ss");
        case 'DOMStringDate':
          return format(parsed, "yyyy-MM-dd");
        case 'DOMStringTime':
          return format(parsed, "HH:mm:ss");
        case 'ISO':
          return formatISO(parsed);
        default:
          break;
      }
    }
    return format(parsed, "yyyy/MM/dd HH:mm");
  }
};

export default dateTime;
