import { Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';

export default function BooleanHighight({ bool, onChange, id }) {
  const [value, setValue] = useState(bool === 1);

  useEffect(() => {
    setValue(bool === 1);
  }, [bool]);

  const handleOnChange = (e) => {

    if (!window.confirm("Are You Sure?")) {
      return;
    }

    setValue(!value);
    onChange({ id, checked: e.target.checked });
  };

  return <Switch checked={value} onChange={handleOnChange} color="success" />;
}
