import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { unexpectedError, defaultError } from 'utils/message';
import NavBar from './NavBar';
import Sidebar from './Sidebar';
import { fetchMe } from 'apiRequest/me';
import { useQuery } from 'react-query';
import depositAudio from 'audios/deposit.wav';
import withdrawAudio from 'audios/withdraw.wav';
import testAudio from 'audios/test.mp3';

export default function PrivateLayout({ children, loading, isError, error }) {
  const { removeToken } = useToken();
  let navigate = useNavigate();
  const [notificationMuted, setNotificationMuted] = useState(localStorage.getItem("notificationMuted") === "true");

  if (isError && error?.response.status === 401) {
    removeToken();
    navigate('/login', { replace: true });
  }

  const { token } = useToken();
  const { isLoading, isErrorFetchMe, data: me, errorFetchMe } = useQuery("me-fetchMe", () => {
    return fetchMe({ token, params: { partials: ['server'] } });
  });

  if (isErrorFetchMe) {
    toast.error(errorFetchMe.message || defaultError);
    console.error(errorFetchMe);
  }

  const depositAudioEle = new Audio(depositAudio);
  const withdrawAudioEle = new Audio(withdrawAudio);
  const testAudioEle = new Audio(testAudio);

  const serverId = me?.data.data.server_id;
  const channelName = `private-server.${serverId}`;

  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.removeItem("audioActive");
      if (window.Pusher.channel(channelName)) {
        window.Pusher.unsubscribe(channelName);
      }
    };
  }, [channelName]);

  useEffect(() => {
    if (!serverId) return;

    if (notificationMuted) {
      // unsubscribe the channel on page refresh, tab close
      if (window.Pusher.channel(channelName)) {
        window.Pusher.unsubscribe(channelName);
      }
    } else {
      if (!window.Pusher.channel(channelName)) {
        var channel = window.Pusher.subscribe(channelName);
        channel.bind('deposit.created', function (data) {
          toast(`${data.deposit.amount} deposit from game id: ${data.deposit.game_id_ref}`,
            {
              icon: "🔔",
              position: "top-right",
            });
          depositAudioEle.play();
        });
        channel.bind('withdraw.created', function (data) {
          toast(`${data.withdraw.amount} withdraw from game id: ${data.withdraw.game_id_ref}`,
            {
              icon: "🔔",
              position: "top-right",
            });
          withdrawAudioEle.play();
        });
      }
    }
    // eslint-disable-next-line
  }, [notificationMuted, serverId, channelName]);

  const handleNotificationToggle = () => {
    const muted = !notificationMuted;
    setNotificationMuted(muted);
    localStorage.setItem("notificationMuted", muted);
  };

  const [audioActive, setAudioActive] = useState(localStorage.getItem("audioActive") === "true");
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    localStorage.setItem("audioActive", "true");
    setAudioActive(true);
    setDialogOpen(false);
  };

  useEffect(() => {
    if (!audioActive) {
      testAudioEle.play().then(() => {
        setAudioActive(true);
        localStorage.setItem("audioActive", "true");
      }).catch(() => {
        localStorage.setItem("audioActive", "false");
        setDialogOpen(true);
      });
    }
  // eslint-disable-next-line
  }, [audioActive]);

  return (
    <>
      <Dialog open={dialogOpen}>
        <DialogTitle>
          Sound on Notification is Muted
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Notification sound on your system is muted, please click "Unmuted" to open the sound of notification.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} >
            Unmuted
          </Button>
        </DialogActions>
      </Dialog>
      <Box>
        <NavBar notificationMuted={notificationMuted} handleNotificationToggle={handleNotificationToggle} />
        <Box top="60px" position="absolute" width="100%" zIndex="1300">
          {loading && <LinearProgress />}
        </Box>
        <Box display='flex'>
          <Sidebar me={me} isLoading={isLoading} />
          <Box component="main" ml="256px" p={4} mt="64px" flex="1">
            <Box position="absolute" right="0" minWidth="100px">
              {isError && error?.response.status !== 404 && <Alert severity="error">
                {error?.message || unexpectedError}
              </Alert>}
            </Box>
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
}
