import React from 'react';

import Login from "pages/Login";
import NotFound from "pages/NotFound";
import PrivateRoute from "PrivateRoute";

import { Navigate, Route, Routes } from "react-router-dom";
import TransactionList from 'pages/transactions/list';
import TransactionShow from 'pages/transactions/show';
import TransactionCreate from 'pages/transactions/create';
import DealerPaymentList from 'pages/dealer-payments/list';
import DealerPaymentUpdate from 'pages/dealer-payments/update';
import DealerPaymentCreate from 'pages/dealer-payments/create';
import TransactionReportMontly from 'pages/transaction-reports/monthly';
import TransactionReportYearly from 'pages/transaction-reports/yearly';
import TransactionReportDaily from 'pages/transaction-reports/daily';
import DealerPaymentReportList from 'pages/dealer-payments-reports/list';
import TransactionUpdate from 'pages/transactions/update';
import OCR from 'pages/ocr/ocr';

export default function WebRoutes() {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route element={<PrivateRoute />}>
        <Route index element={<TransactionList />} />
        
        <Route path="/transactions" element={<TransactionList />} />
        <Route path="/transactions/:id" element={<TransactionShow />} />
        <Route path="/transactions/create" element={<TransactionCreate />} />
        <Route path="/transactions/:id/update" element={<TransactionUpdate />} />

        <Route path="/reports/transactions" element={<Navigate to="/reports/transactions/daily" replace={true} />} />
        <Route path="/reports/transactions/daily" element={<TransactionReportDaily />} />
        <Route path="/reports/transactions/monthly" element={<TransactionReportMontly />} />
        <Route path="/reports/transactions/yearly" element={<TransactionReportYearly />} />

        <Route path="/dealer-payments" element={<DealerPaymentList />} />
        <Route path="/dealer-payments/create" element={<DealerPaymentCreate />} />
        <Route path="/dealer-payments/:id/update" element={<DealerPaymentUpdate />} />
        <Route path="/reports/dealer-payments" element={<DealerPaymentReportList />} />

        <Route path="/ocr" element={<OCR />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
