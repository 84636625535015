import { Logout as LogoutIcon } from '@mui/icons-material';

import logo from 'logo.png';
import { NavLink, useNavigate } from 'react-router-dom';

import { AppBar, Box, IconButton, Toolbar, CircularProgress, ToggleButton } from '@mui/material';
import useToken from 'hooks/useToken';
import { logoutMe } from 'apiRequest/me';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { defaultError } from 'utils/message';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';

export default function NavBar({ notificationMuted, handleNotificationToggle }) {
  const { removeToken, token } = useToken();
  const navigate = useNavigate();

  const mutation = useMutation(() => {
    return logoutMe({ token });
  });

  const handleLogout = async () => {
    mutation.mutate(null, {
      onSuccess: () => {
        removeToken();
        navigate('/login', { replace: true });
      },
      onError: (error) => {
        console.error(error);
        toast.error(defaultError);
      }
    });
  };

  return (
    <AppBar>
      <Toolbar>
        <NavLink to="/">
          <img src={logo} height="56" alt="Lotto ASEAN" />
        </NavLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ position: 'relative' }}>
          <ToggleButton
            sx={{
              border: "none"
            }}
            value="notifications"
            onChange={() => {
              handleNotificationToggle();
            }}
          >
            {notificationMuted ? <NotificationsOffOutlinedIcon /> : < NotificationsActiveOutlinedIcon />}
          </ToggleButton>
          <IconButton color="inherit" onClick={handleLogout} disabled={mutation.isLoading}>
            <LogoutIcon />
          </IconButton>
          {mutation.isLoading && <CircularProgress
            sx={{
              position: 'absolute',
              left: -3,
              zIndex: 1,
            }}
          />}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
