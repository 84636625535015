import { Button, Grid, Link } from '@mui/material';
import { fetchTransactions } from 'apiRequest/transaction';
import SelectInput from 'components/forms/fields/SelectInput';
import ISTable from 'components/lSTable';
import StatusHighight from 'components/StatusHighight';
import useToken from 'hooks/useToken';
import PrivateLayout from 'layouts/private';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import dateTime from 'utils/datetime';
import { statusOptions } from 'utils/status';
import { refetchIntervalTime } from 'config';
import TableButtonsContainer from 'components/TableButtonsContainer';
import { fetchPaymentProviders } from 'apiRequest/payment-provider';
import { fetchDealerPaymentsAll } from 'apiRequest/dealer-payment';
import { toast } from 'react-hot-toast';
import { defaultError } from 'utils/message';
import groupBy from 'lodash.groupby';
import { useFormState } from 'informed';

export default function TransactionList() {
  const { token } = useToken();

  const {
    isLoading: isLoadingDealerPayments,
    isError: isErrorDealerPayments,
    data: dealerPayments,
    error: errorDealerPayments
  } = useQuery(
    ["dealerPayment-fetchDealerPaymentsAll"],
    () => {
      return fetchDealerPaymentsAll({ token, params: { server_code: 'obet99', filters: { is_active: 1 } } });
    });

  if (isErrorDealerPayments) {
    toast.error(errorDealerPayments.message || defaultError);
    toast.error('contact admin');
    console.error(errorDealerPayments);
  }

  const {
    isError: isErrorPaymentProvider,
    data: paymentProviders,
    error: errorPaymentProvider
  } = useQuery("payment-fetchPaymentProviders", () => {
    return fetchPaymentProviders({ token, params: { server_code: 'obet99' } });
  });

  if (isErrorPaymentProvider) {
    toast.error(errorPaymentProvider.message || defaultError);
    toast.error('contact admin');
    console.error(errorPaymentProvider);
  }

  const dealerPaymentGroups = groupBy(dealerPayments?.data.data, (d) => d.provider);
  const dealerPaymentGroupKeys = Object.keys(dealerPaymentGroups);
  const paymentProviderOptions = React.useMemo(() => (
    paymentProviders?.data.data.filter((provider) => dealerPaymentGroupKeys.includes(provider.value))
  ), [paymentProviders, dealerPaymentGroupKeys]);

  const [params, setParams] = useState({
    partials: ['server', 'staff', 'dealer_payment'],
    sort_by: {
      "key": "id",
      "order": "desc"
    }
  });

  const { isLoading, isErrorTransaction, data: transactions, errorTransaction } = useQuery(
    ["transaction-fetchTransactions", params],
    () => {
      return fetchTransactions({ token, params });
    }, {
    refetchInterval: refetchIntervalTime
  });

  const handlePageChange = (page) => {
    setParams({ ...params, page });
  };

  const handleSearchSubmit = (search) => {
    setParams({
      ...params,
      filters: { ...params.filters, search }
    });
  };

  const hanldeFilterSubmit = (data) => {
    setParams({
      ...params,
      filters: { ...params.filters, ...data }
    });
  };

  const hanldeSortClick = (data) => {
    setParams({ ...params, sort_by: data });
  };

  const columns = React.useMemo(() => [
    {
      key: 'id',
      label: 'ID',
      selector: row => <Link component={RouterLink} to={`/transactions/${row.id}`}>{row.id}</Link>,
      sort: true,
    },
    {
      key: 'type',
      label: 'Type',
      selector: row => row.type,
    },
    {
      key: 'game_id_ref',
      label: 'Game ID',
      selector: row => row.game_id_ref,
    },
    {
      key: 'dealer_payment.provider',
      label: 'Payment Provider',
      selector: row => row.dealer_payment?.provider,
    },
    {
      key: 'dealer_payment.account_id',
      label: 'Payment ID/Phone',
      selector: row => row.dealer_payment?.account_id,
    },
    {
      key: 'amount',
      label: 'Amount',
      selector: row => row.amount,
      currency: true
    },
    {
      key: 'bonus',
      label: 'Bonus',
      selector: row => row.bonus,
      currency: true
    },
    {
      key: 'transaction_id_ref',
      label: 'Transaction ID',
      selector: row => row.transaction_id_ref,
    },
    {
      key: 'transferred_at',
      label: 'Transferred At',
      selector: row => dateTime.format(row.transferred_at),
      sort: true
    },
    {
      key: 'staff.name',
      label: 'Staff',
      selector: row => row.staff?.name,
    },
    {
      key: 'status',
      label: 'Status',
      selector: row => <StatusHighight status={row.status} />
    },
  ], []);

  return (
    <PrivateLayout loading={isLoading} isError={isErrorTransaction} error={errorTransaction}>
      <TableButtonsContainer>
        <Button
          variant="contained"
          component={RouterLink}
          to={`/transactions/create`}
        >
          Add New Transaction
        </Button>
      </TableButtonsContainer>

      <ISTable
        title="Transactions"
        loading={isLoading}
        columns={columns}
        data={transactions?.data.data}
        pagination
        count={transactions?.data.total}
        page={transactions?.data.current_page}
        rowsPerPage={transactions?.data.per_page}
        onPageChange={handlePageChange}
        search
        searchFields={['game_id_ref', 'note']}
        searchSubmit={handleSearchSubmit}
        filter
        filterSubmit={hanldeFilterSubmit}
        FilterFields={FilterFields}
        filterFields={['status', 'type', 'dealer_payment_dot_provider', 'dealer_payment_dot_account_id']}
        filterFieldsProps={{ paymentProviderOptions, dealerPaymentGroups }}
        sortClick={hanldeSortClick}
      />
    </PrivateLayout>
  );
}

const FilterFields = ({ paymentProviderOptions, dealerPaymentGroups }) => {

  const formState = useFormState();

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} width="250px" >
      <Grid item xs={6}>
        <SelectInput field="status" label="Status" size="small" margin="none" helperText={false}
          options={statusOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput field="type" label="Type" size="small" margin="none" helperText={false}
          options={[
            {
              label: "Deposit",
              value: "deposit"
            },
            {
              label: "Withdraw",
              value: "withdraw"
            }
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectInput field="dealer_payment_dot_provider" label="Payment Provider" size="small" margin="none" helperText={false}
          options={paymentProviderOptions}
        />
      </Grid>
      <Grid item xs={12}>
        {formState.values.dealer_payment_dot_provider && (
          <SelectInput field="dealer_payment_dot_account_id" label="Payment Account ID/Phone" size="small" margin="none" helperText={false}
            options={dealerPaymentGroups[formState.values.dealer_payment_dot_provider].map((payment) => (
              {
                label: `${payment.account_name} | ${payment.account_id}`,
                value: payment.account_id
              }
            ))}
          />
        )}
      </Grid>
    </Grid>
  );
};
