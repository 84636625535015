import * as Yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(Yup);

const updateDealerPaymentValidation = Yup.object().shape({
  provider: Yup.string().required().oneOf(['aya_bank', 'cb_bank', 'cb_pay', 'kbz_bank', 'kbz_pay', 'wave_pay', 'aya_pay', 'thai_bank']),
  account_id: Yup.string().required(),
  account_name: Yup.string(),
  is_active: Yup.boolean(),
});

export default updateDealerPaymentValidation;
