import useToken from 'hooks/useToken';
import Pusher from 'pusher-js';
import { Navigate, Outlet } from 'react-router-dom';

export default function PrivateRoute({ element, ...rest }) {
  let { token } = useToken();
  const loggedIn = token != null;

  if (loggedIn) {
    if (!window.Pusher.key) {
      window.Pusher.logToConsole = true;

      window.Pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        authEndpoint: process.env.REACT_APP_PUSHER_AUTH_URL,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
    }

    return (
      <Outlet />
    );
  }

  return <Navigate to="/login" replace />;
}
