import fetch from "utils/fetch";

export const fetchDealerPayments = ({ params, ...rest }) => {
  return fetch({
    url: `/dealer-payments`,
    method: "GET",
    params,
    ...rest
  });
};

export const fetchDealerPaymentsAll = ({ params, ...rest }) => {
  return fetch({
    url: `/dealer-payments/all`,
    method: "GET",
    params,
    ...rest
  });
};

export const fetchDealerPayment = ({ id, params, ...rest }) => {
  return fetch({
    url: `/dealer-payments/${id}`,
    method: "GET",
    params,
    ...rest
  });
};


export const updateDealerPayment = ({ id, data, ...rest }) => {
  return fetch({
    url: `/dealer-payments/${id}`,
    method: "PUT",
    data,
    ...rest
  });
};

export const createDealerPayment = ({ data, ...rest }) => {
  return fetch({
    url: `/dealer-payments`,
    method: "POST",
    data,
    ...rest
  });
};


export const updateDealerPaymentActivation = ({ id, data, ...rest }) => {
  return fetch({
    url: `/dealer-payments/${id}/activation`,
    method: "PUT",
    data,
    ...rest
  });
};
